export default {
  dashboard: [
    {
      title: 'Finance',
      route: 'finance',
      icon: 'BarChart2Icon',
      resource: 'dashboard',
      action: 'access-finance-dashboard',
    },
    {
      title: 'Students',
      route: 'students',
      icon: 'PieChartIcon',
      resource: 'dashboard',
      action: 'access-students-dashboard',
    },
    {
      title: 'Transportation',
      route: 'transportation',
      icon: 'BarChartIcon',
      resource: 'dashboard',
      action: 'access-transportation-dashboard',
    },
    {
      title: 'User Guide',
      route: 'dashboard-guide',
      icon: 'HelpCircleIcon',
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  ],
  administration: [
    {
      title: 'School Account',
      route: 'school-account',
      icon: 'HomeIcon',
      resource: 'administration',
      action: 'access-school-account',
    },
    {
      title: 'Account Users',
      route: 'account-users',
      icon: 'UsersIcon',
      resource: 'administration',
      action: 'access-account-users',
    },
    {
      title: 'Notifications',
      route: 'notifications',
      icon: 'MessageCircleIcon',
      resource: 'administration',
      action: 'access-notifications',
    },
    {
      title: 'Creche Reports',
      route: 'reports',
      icon: 'ListIcon',
      resource: 'reports',
      action: 'access-creche-reports',
    },
    {
      title: 'Academics',
      icon: 'BookIcon',
      children: [
        {
          title: 'Terms & Classes',
          route: 'terms-and-classes',
          icon: 'CircleIcon',
          resource: 'administration',
          action: 'access-terms-and-classes',
        },
        {
          title: 'Subjects & Grading',
          route: 'subjects-grading',
          icon: 'CircleIcon',
          resource: 'administration',
          action: 'access-subjects-and-grading',
        },
        {
          title: 'Student Promotion',
          route: 'student-promotion',
          icon: 'CircleIcon',
          resource: 'administration',
          action: 'access-student-promotion',
        },
        {
          title: 'Report Remarks',
          route: 'report-remarks',
          icon: 'CircleIcon',
          resource: 'administration',
          action: 'access-report-remarks',
        },
        {
          title: 'Report Card Options',
          route: 'report-card-options',
          icon: 'CircleIcon',
          resource: 'administration',
          action: 'access-report-card-options',
        },
        {
          title: 'Assessment %',
          route: 'assessment-settings',
          icon: 'CircleIcon',
          resource: 'administration',
          action: 'access-assessment-settings',
        },
      ],
    },
    // {
    //   title: 'Student ID Card',
    //   route: 'student-id-cards',
    //   icon: 'UserIcon',
    //   resource: 'administration',
    //   action: 'access-student-id-cards',
    // },
    // {
    //   title: 'Signatures',
    //   route: 'document-signatures',
    //   icon: 'EditIcon',
    //   resource: 'administration',
    //   action: 'access-document-signatures',
    // },
    {
      title: 'User Activities',
      route: 'user-activities',
      icon: 'ActivityIcon',
      resource: 'administration',
      action: 'access-user-activities',
    },
    {
      title: 'User Guide',
      route: 'admin-guide',
      icon: 'HelpCircleIcon',
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  ],
  students: [
    {
      title: 'Students',
      route: 'registration',
      icon: 'UserPlusIcon',
      resource: 'students',
      action: 'access-registration',
    },
    {
      title: 'Attendance',
      route: 'attendance',
      icon: 'UserCheckIcon',
      resource: 'students',
      action: 'access-attendances',
    },
    {
      title: 'Unmarked',
      route: 'unmarked-attendances',
      icon: 'UserXIcon',
      resource: 'students',
      action: 'access-unmarked-attendances',
    },
    {
      title: 'Old Students',
      route: 'old-students',
      icon: 'UsersIcon',
      resource: 'students',
      action: 'access-old-students',
    },
    {
      title: 'ID Customization',
      route: 'id-customization',
      icon: 'UserIcon',
      resource: 'students',
      action: 'access-id-customization',
    },
    {
      title: 'User Guide',
      route: 'students-guide',
      icon: 'HelpCircleIcon',
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  ],
  academics: [
    {
      title: 'Assessments',
      route: 'assessments',
      icon: 'CheckSquareIcon',
      resource: 'academics',
      action: 'access-student-assessments',

    },
    {
      title: 'Reports',
      route: 'results',
      icon: 'ClipboardIcon',
      resource: 'academics',
      action: 'access-report-cards',
    },
    {
      title: 'Performances',
      route: 'performances',
      icon: 'BarChart2Icon',
      resource: 'academics',
      action: 'access-performances',
    },
    {
      title: 'User Guide',
      route: 'academics-guide',
      icon: 'HelpCircleIcon',
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  ],
  finance: [
    {
      title: 'Fees',
      icon: 'SettingsIcon',
      children: [
        {
          title: 'Fee Settings',
          route: 'fee-settings',
          icon: 'CircleIcon',
          resource: 'finance',
          action: 'access-fee-settings',
        },
        {
          title: 'Fee Payments',
          route: 'fee-payments',
          icon: 'CircleIcon',
          resource: 'finance',
          action: 'access-fee-payments',
        },
        {
          title: 'Fee Receipts',
          route: 'fee-receipts',
          icon: 'CircleIcon',
          resource: 'finance',
          action: 'access-fees-and-receipts',
        },
        {
          title: 'Arrears List',
          route: 'arrears-list',
          icon: 'CircleIcon',
          resource: 'finance',
          action: 'access-arrears-list',
        },
        {
          title: 'Recurring Fees',
          route: 'recurring-fees',
          icon: 'CircleIcon',
          resource: 'finance',
          action: 'access-recurring-fees',
        },
        {
          title: 'Fee Projections',
          route: 'fee-projections',
          icon: 'CircleIcon',
          resource: 'finance',
          action: 'access-fee-projections',
        },
      ],
    },
    {
      title: 'Other Income',
      route: 'other-income',
      icon: 'PlusIcon',
      resource: 'finance',
      action: 'access-other-income',
    },
    {
      title: 'Expenses',
      route: 'expenses',
      icon: 'MinusIcon',
      resource: 'finance',
      action: 'access-expenses',
    },
    // {
    //   title: 'Transfers',
    //   route: 'transfers',
    //   icon: 'RepeatIcon',
    //   resource: 'administration',
    //   action: 'access-transfers',
    // },
    {
      title: 'User Guide',
      route: 'finance-guide',
      icon: 'HelpCircleIcon',
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  ],
  staff: [
    {
      title: 'Members',
      route: 'staff-income',
      icon: 'UsersIcon',
      resource: 'staff',
      action: 'access-income',
    },
    {
      title: 'Payments',
      route: 'staff-arrears',
      icon: 'DollarSignIcon',
      resource: 'staff',
      action: 'access-arrears',
    },
    // {
    //   title: 'Wallet',
    //   route: 'wallet',
    //   icon: 'PlusCircleIcon',
    //   resource: 'staff',
    //   action: 'access-wallet',
    // },
    {
      title: 'Attendance',
      route: 'staff-attendance',
      icon: 'UserCheckIcon',
      resource: 'staff',
      action: 'access-staff-attendance',
    },
    {
      title: 'User Guide',
      route: 'staff-guide',
      icon: 'HelpCircleIcon',
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  ],
  transportation: [
    {
      title: 'Trips',
      icon: 'TruckIcon',
      children: [
        {
          title: 'Setup',
          route: 'setup-trips',
          icon: 'CircleIcon',
          resource: 'transportation',
          action: 'access-setup-trips',
        },
        {
          title: 'Requests',
          route: 'trip-requests',
          icon: 'CircleIcon',
          resource: 'transportation',
          action: 'access-trip-requests',
        },
      ],
    },
    {
      title: 'Reports',
      icon: 'BarChart2Icon',
      children: [
        {
          title: 'Completed',
          route: 'completed-trips',
          icon: 'CircleIcon',
          resource: 'transportation',
          action: 'access-completed-trips',
        },
        {
          title: 'Attendance',
          route: 'trip-attendance',
          icon: 'CircleIcon',
          resource: 'transportation',
          action: 'access-trip-attendance',
        },
      ],
    },
    {
      title: 'Settings',
      icon: 'SettingsIcon',
      children: [
        {
          title: 'Vehicles',
          route: 'vehicles',
          icon: 'CircleIcon',
          resource: 'transportation',
          action: 'access-vehicles',
        },
        {
          title: 'Pickup Points',
          route: 'pickup-points',
          icon: 'CircleIcon',
          resource: 'transportation',
          action: 'access-pickup-points',
        },
      ],
    },
    {
      title: 'User Guide',
      route: 'transportation-guide',
      icon: 'HelpCircleIcon',
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  ],
  documents: [
    {
      title: 'Documents',
      route: 'documents',
      icon: 'FolderIcon',
      resource: 'documents',
      action: 'access-documents',
    },
    {
      title: 'Timetable Planner',
      route: 'timetable',
      icon: 'CalendarIcon',
      resource: 'documents',
      action: 'access-timetable',
    },
    {
      title: 'Lesson Notes',
      route: 'lesson-notes',
      icon: 'FileTextIcon',
      resource: 'documents',
      action: 'access-lesson-notes',
    },
    {
      title: 'Books & Questions',
      route: 'questions',
      icon: 'BookIcon',
      resource: 'documents',
      action: 'access-questions',
    },
    // {
    //   title: 'Smart Marker',
    //   icon: 'SettingsIcon',
    //   children: [
    //     {
    //       title: 'Marker Settings',
    //       route: 'marker-settings',
    //       icon: 'CircleIcon',
    //       resource: 'documents',
    //       action: 'access-marker-settings',
    //     },
    //     {
    //       title: 'Marked Assessments',
    //       route: 'marked-assessments',
    //       icon: 'CircleIcon',
    //       resource: 'documents',
    //       action: 'access-marked-assessments',
    //     },
    //   ],
    // },
    {
      title: 'Credits',
      route: 'credits',
      icon: 'PlusIcon',
      resource: 'documents',
      action: 'access-credits',
    },
    {
      title: 'Trash Store',
      route: 'trash-store',
      icon: 'TrashIcon',
      resource: 'documents',
      action: 'access-trash-store',
    },
    {
      title: 'User Guide',
      route: 'docs-guide',
      icon: 'HelpCircleIcon',
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  ],
  // smartmarker: [
  //   {
  //     title: 'Marker Settings',
  //     route: 'marker-settings',
  //     icon: 'SettingsIcon',
  //     resource: 'smartmarker',
  //     action: 'access-marker-settings',
  //   },
  //   {
  //     title: 'Marked Booklets',
  //     route: 'marked-booklets',
  //     icon: 'CheckCircleIcon',
  //     resource: 'smartmarker',
  //     action: 'access-marked-booklets',
  //   },
  //   {
  //     title: 'User Guide',
  //     route: 'smartmarker-guide',
  //     icon: 'HelpCircleIcon',
  //     resource: 'lenon',
  //     action: 'access-lenon-app',
  //   },
  // ],
  accounts: [
    {
      title: 'SMS & Payments',
      route: 'sms-account',
      icon: 'MessageCircleIcon',
      resource: 'accounts',
      action: 'access-sms-account',
    },
    {
      title: 'Failed SMS',
      route: 'failed-sms',
      icon: 'XCircleIcon',
      resource: 'accounts',
      action: 'access-failed-sms',
    },
    {
      title: 'Subscription',
      route: 'subscription',
      icon: 'PackageIcon',
      resource: 'accounts',
      action: 'access-subscription',
    },
    {
      title: 'User Guide',
      route: 'accounts-guide',
      icon: 'HelpCircleIcon',
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  ],
}
